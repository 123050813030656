import { useMutation } from "@tanstack/react-query";
import preBookingApiClient from "./preBookingApiClient";

interface IReqBody {
  ticketId: string;
  arsannRate: number;
  arsannComment: string;
  employeeRate: number;
  employeeComment: string;
}

export const useAddFeedbackMutation = () =>
  useMutation<void, any, IReqBody>({
    mutationFn: async (data) => {
      return preBookingApiClient.post(`/feedback`, data);
    },
  });
