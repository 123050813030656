import React, { useEffect, useState } from "react";
import { PreBookingTobBar } from "./components/top-bar";
import { Box, CircularProgress } from "@mui/material";
import BookingForm from "./components/booking-form";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormFields, schema } from "./constants";
import CarsImage from "src/assets/cars.png";
import BookedSuccessfully from "./components/booked-successfully";
import PreBookingPaymentFailed from "./components/payment-failed";
import NoLocationFound from "./components/no-location-found";
import {
  useGetBookingMutation,
  useGetPreBookingLocationMutation,
} from "src/api/pre-booking";
import { useParams, useSearchParams } from "react-router-dom";

const PreBooking: React.FC = () => {
  const params = useParams();
  let [searchParams] = useSearchParams();
  const bookingId = searchParams.get("bookingId");
  const [bookingStatus, setBookingStatus] = useState<
    "init" | "paid" | "failedPayment"
  >("init");

  // APIS
  const { mutate, data, isPending, error } = useGetPreBookingLocationMutation();
  const {
    mutate: mutateGetBooking,
    data: bookingData,
    isPending: isPendingGetBooking,
    status: getBookingStatus,
  } = useGetBookingMutation();

  // React hook form
  const methods = useForm<FormFields>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      mobileNumber: "",
    },
  });

  useEffect(() => {
    mutate({ locationId: params.locationId as string });
  }, []);

  useEffect(() => {
    if (bookingId) {
      mutateGetBooking({ bookingId: bookingId });
    }
  }, [bookingId]);

  useEffect(() => {
    if (getBookingStatus === "success") {
      setBookingStatus("paid");
    } else if (getBookingStatus === "error") {
      setBookingStatus("failedPayment");
    }
  }, [getBookingStatus]);

  console.log({ data, isPending, isPendingGetBooking, bookingData });

  return (
    <Box
      display="flex"
      px={{ xs: 2, sm: 4 }}
      position={"relative"}
      pb={6}
      justifyContent="center"
    >
      <Box position={"absolute"} top={"0"} zIndex={0}>
        <img
          src={CarsImage}
          height={408}
          width={"100%"}
          style={{ objectFit: "cover" }}
          alt=""
        />
      </Box>
      <Box width={"100%"} maxWidth={"1340px"} zIndex={3}>
        <PreBookingTobBar />
        <Box>
          {!isPendingGetBooking && !isPending ? (
            <FormProvider {...methods}>
              {!bookingId ? (
                <>
                  {error ? (
                    <NoLocationFound />
                  ) : (
                    <BookingForm locationData={data?.data} />
                  )}
                </>
              ) : (
                <>
                  {bookingData?.data.status === "booked" && (
                    <BookedSuccessfully bookingData={bookingData?.data} />
                  )}
                  {bookingStatus === "failedPayment" && <PreBookingPaymentFailed />}
                </>
              )}
            </FormProvider>
          ) : (
            <Box pt={"130px"} display={"flex"} justifyContent={"center"}>
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default PreBooking;
