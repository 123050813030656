import { useMutation } from "@tanstack/react-query";
import preBookingApiClient from "./preBookingApiClient";
import { ILocation } from "src/types/pre-booking";

interface IInitiateBookingBody {
  locationId: string;
  name: string;
  isoCode: string;
  countryCode: string;
  mobileNumber: string; //549246529
  arrivingTime: number;
}

interface IBookingResponse {
  arrivingTime: number;
  locationName: string;
  status: string;
  ticketId: string;
}

export const useGetPreBookingLocationMutation = () =>
  useMutation<{ data: ILocation }, { error: any }, { locationId: string }>({
    mutationFn: async (data) => {
      return preBookingApiClient.post(`/pre-booking/getLocation`, data);
    },
  });

export const useInitiateBookingMutation = () =>
  useMutation<
    { data: { paymentUrl: string } },
    { error: any },
    IInitiateBookingBody
  >({
    mutationFn: async (data) => {
      return preBookingApiClient.post(`/pre-booking/initiateBooking`, data);
    },
  });

export const useGetBookingMutation = () =>
  useMutation<
    {
      data: IBookingResponse;
    },
    { error: any },
    { bookingId: string }
  >({
    mutationFn: async (data) => {
      return preBookingApiClient.post(`/pre-booking/getBooking`, data);
    },
  });
