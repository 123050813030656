import { Box, Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import SuccessIcon from "src/assets/svg-icons/success.svg";
import { ICarRequest } from "src/types/car-request";
import { TicketInfoBox } from "../ticket-info-box";

const RequestedSuccessfully = ({
  data,
  setCurrentStep,
  isPartialPaid,
}: {
  data: ICarRequest;
  setCurrentStep: Function;
  isPartialPaid: boolean;
}) => {
  const { t } = useTranslation();

  const { enableFreeLocation, price } = data || {};

  const isFreeTicket = enableFreeLocation || price === 0;

  const handlePayNow = () => {
    setCurrentStep(2); // go to payment step
  };

  return (
    <Box pb={3}>
      <Stack alignItems={"center"} mb={2}>
        <img width={130} src={SuccessIcon} alt="" />
        <Typography mt={1} mb={1.2} fontSize={"24px"} fontWeight={600}>
          {t("requestedSuccessfully")}
        </Typography>
        {!isFreeTicket ? (
          <Typography align="center">{t("after30MinutesExtraFees")}</Typography>
        ) : null}
      </Stack>
      <TicketInfoBox data={data} />
      {isPartialPaid && (
        <Button
          fullWidth
          variant="contained"
          sx={{ mt: "20px" }}
          onClick={handlePayNow}
        >
          {t("payNow")}
        </Button>
      )}
    </Box>
  );
};

export default RequestedSuccessfully;
