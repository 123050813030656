import { useMutation, useQuery } from "@tanstack/react-query";
import { APIErrorResponse } from "src/types/generic";
import preBookingApiClient from "./preBookingApiClient";

interface IInitiateBookingBody {
  locationId: string;
  hostName: string;
  hostIsoCode: "SA";
  hostCountryCode: string;
  hostMobileNumber: string;
  customerName: string;
  customerIsoCode: "SA";
  customerCountryCode: string;
  customerMobileNumber: string;
  arrivingTime: number;
  leavingTime: number;
}

interface IBookingResponse {
  arrivingTime: number;
  locationName: string;
  status: string;
  ticketId: string;
}

export const useInitiateKAFDBookingMutation = () =>
  useMutation<
    { data: { paymentUrl: string } },
    { error: any },
    IInitiateBookingBody
  >({
    mutationFn: async (data) => {
      return preBookingApiClient.post(
        `/kafd-pre-booking/initiateBooking`,
        data
      );
    },
  });

export const useGetKAFDBooking = (bookingId: string) =>
  useQuery<any, any, any>({
    queryKey: ["get-kafd-booking-by-id"],
    queryFn: async () => {
      return preBookingApiClient.get(
        `/kafd-pre-booking/getBooking/${bookingId}`
      );
    },
    enabled: !!bookingId,
  });

export const useGetKAFDLocations = () =>
  useQuery<any, any, any>({
    queryKey: ["get-kafd-locations"],
    queryFn: async () => {
      return preBookingApiClient.get(`/kafd-pre-booking/getLocations`);
    },
  });

export const useUpdateKafdBookingMutation = () =>
  useMutation<any, APIErrorResponse, { id: string; newLeavingTime: number }>({
    mutationFn: async (data) => {
      const { id, ...rest } = data;
      return preBookingApiClient.put(
        `/kafd-pre-booking/updateBooking/${id}`,
        rest
      );
    },
  });
