import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormData } from "../../constants";

const TimeAndGate = ({
  exitGates,
}: {
  exitGates: {
    name: string;
    isActive: boolean;
  }[];
}) => {
  const { t } = useTranslation();

  const {
    control,
    formState: { errors },
  } = useFormContext<FormData>();

  return (
    <>
      <Box mt={"30px"}>
        <InputLabel id="demo-simple-select-standard-label" sx={{ mb: 0.5 }}>
          {t("selectTimeToPrepare")}
        </InputLabel>
        <FormControl fullWidth error={!!errors.time} size="small">
          <Controller
            name="time"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                displayEmpty
                labelId="demo-simple-select-label"
                id="demo-simple-select"
              >
                <MenuItem value="" disabled>
                  {t("selectTime")}
                </MenuItem>
                <MenuItem value={10}>10 {t("minutes")}</MenuItem>
                <MenuItem value={15}>15 {t("minutes")}</MenuItem>
                <MenuItem value={20}>20 {t("minutes")}</MenuItem>
              </Select>
            )}
          />
          <FormHelperText id="my-helper-text">
            {t(`${errors.time?.message ?? ""}`)}
          </FormHelperText>
        </FormControl>
      </Box>
      {exitGates.length > 1 && (
        <Box mt={3}>
          <InputLabel id="demo-simple-select-standard-label" sx={{ mb: 0.5 }}>
            {t("selectPickupGate")}
          </InputLabel>
          <FormControl fullWidth error={!!errors.gate} size="small">
            <Controller
              name="gate"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  displayEmpty
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                >
                  <MenuItem value="" disabled>
                    {t("selectGate")}
                  </MenuItem>
                  {exitGates.map((gate) => {
                    return (
                      <MenuItem key={gate.name} value={gate.name}>
                        {gate.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            />
            <FormHelperText id="my-helper-text">
              {t(`${errors.gate?.message ?? ""}`)}
            </FormHelperText>
          </FormControl>
        </Box>
      )}
    </>
  );
};

export default TimeAndGate;
