import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import ClockIcon from "src/assets/svg-icons/clock.svg";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import { LoadingButton } from "@mui/lab";
import {
  useGetKAFDBooking,
  useUpdateKafdBookingMutation,
} from "src/api/kafd-prebooking";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import moment from "moment";
import downArrowIcon from "src/assets/svg-icons/down-arrow.svg";
import sarIcon from "src/assets/svg-icons/sar.svg";

const policyPoints = ["policyPoint1", "policyPoint2", "policyPoint3"];

interface IProps {}

export const schema = Yup.object().shape({
  leavingTime: Yup.date().required("Required"),
});

export interface IFormInputs extends Yup.InferType<typeof schema> {}

const ExtendForm: React.FC<IProps> = () => {
  const { t, i18n } = useTranslation();
  const params = useParams();
  const [openTime, setOpenTime] = useState(false);
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [price, setPrice] = useState(0);
  const [extended, setExtended] = useState(false);

  //   APIS
  const { data: bookingData, isFetching } = useGetKAFDBooking(
    params.id as string
  );
  const { mutate, status, isPending, data } = useUpdateKafdBookingMutation();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    watch,
    setError,
    clearErrors,
  } = useForm<IFormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const newLeavingTime = moment(watch("leavingTime")).valueOf();
  const isNewTimeBeforeOldLeavingTime =
    newLeavingTime <= bookingData?.data.leavingTime;

  const calculatePrice = () => {
    const service = bookingData?.data?.service;
    if (service.pricingType == "free") {
      return 0;
    }
    const arrivalTime = bookingData?.data?.arrivingTime;
    const leaveTime = getValues("leavingTime");
    if (service.pricingType === "custom") {
      const hrDiff =
        Math.abs(leaveTime.getTime() - arrivalTime) / (1000 * 60 * 60);
      const additionalHours = Math.ceil(
        hrDiff - parseInt(service.customStartHrs)
      );
      const exceedingPriceValue: number =
        service.customExceedingPriceType === "allHours" ||
        service.customExceedingPriceType === "fixed"
          ? parseInt(service.customExceedingPrice)
          : additionalHours * parseInt(service.customExceedingPrice);

      return hrDiff > service.customStartHrs
        ? parseInt(service.customPrice) + exceedingPriceValue
        : service.customPrice || 0;
    } else if (service.pricingType === "hourly") {
      const hrDiff =
        Math.abs(leaveTime.getTime() - arrivalTime) / (1000 * 60 * 60);
      const additionalHours = Math.ceil(hrDiff);
      console.log(
        "🚀 ~ calculateNormalValetTicketPriceV3 ~ additionalHours:",
        additionalHours
      );
      return additionalHours * service.hourlyPrice;
    } else {
      return service.fixedPrice || 0;
    }
  };

  const handleOpenTimePicker = () => {
    setOpenTime(true);
  };

  const validateTimeIsAfterOldTime = () => {
    if (isNewTimeBeforeOldLeavingTime) {
      setError("leavingTime", { message: t("pickTimeAfterLeaving") });
    } else {
      clearErrors(["leavingTime"]);
    }
  };

  const handleCloseTimePicker = () => {
    setOpenTime(false);
    validateTimeIsAfterOldTime();
  };

  const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
    console.log({ data });
    validateTimeIsAfterOldTime();

    if (isNewTimeBeforeOldLeavingTime) {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: t("pickTimeAfterLeaving"),
        },
      ]);
      return;
    }

    mutate({
      id: params.id as string,
      newLeavingTime: newLeavingTime,
    });
  };

  useEffect(() => {
    if (bookingData) {
      reset({
        leavingTime: moment(bookingData.data.leavingTime).toDate(),
      });
      console.log("date", moment(bookingData.data.leavingTime).toDate());
    }
  }, [bookingData]);

  useEffect(() => {
    if (status === "success") {
      if (data?.data.paymentUrl) {
        window.location.href = data?.data.paymentUrl;
      } else {
        setNotifications([
          ...notifications,
          {
            type: "success",
            message: t("bookedSuccessfully"),
          },
        ]);
      }
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: t("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  useEffect(() => {
    if (watch("leavingTime")) {
      const price = calculatePrice();
      console.log({ price, bookingData });
      setPrice(price ?? 0);
    }
  }, [watch("leavingTime")]);

  const priceToDisplay = isNewTimeBeforeOldLeavingTime
    ? 0
    : Math.max(price - bookingData?.data?.amount, 0);

  console.log({ price, priceToDisplay, isNewTimeBeforeOldLeavingTime });

  return (
    <Box borderRadius={"12px"} overflow={"hidden"}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          bgcolor={"#272727"}
          padding={{ xs: "30px 20px 100px 20px", md: "30px 113px 100px 113px" }}
          alignItems={"center"}
        >
          {isFetching ? (
            <Box height={"500px"} display={"flex"} alignItems={"center"}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <img src={ClockIcon} height={200} alt="" />

              <Typography
                mb={2}
                color={"primary"}
                fontSize={"24px"}
                fontWeight={700}
                textTransform={"uppercase"}
                align="center"
              >
                {t("updateGuestLeavingTime")}!
              </Typography>
              <Typography mb={2} align="center">
                {t("extraChargesApplied")}
              </Typography>
              <Box maxWidth={"629px"} width={"100%"}>
                <Box width={"100%"}>
                  <Typography sx={{ mb: "10px" }}>
                    {t("leavingTime")}
                  </Typography>
                  <div>
                    <Controller
                      name="leavingTime"
                      control={control}
                      render={({ field }) => (
                        <DesktopTimePicker
                          {...field}
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              error: !!errors.leavingTime,
                              helperText: t(
                                `${errors.leavingTime?.message ?? ""}`
                              ),
                              onClick: handleOpenTimePicker,
                              inputProps: { readOnly: true },
                            },
                          }}
                          ampm={true}
                          format={
                            i18n.language === "en" ? "hh:mm aa" : "hh:mmaa"
                          }
                          open={openTime}
                          onOpen={handleOpenTimePicker}
                          onClose={handleCloseTimePicker}
                        />
                      )}
                    />
                  </div>
                </Box>
                {/* price */}
                <Box width={"100%"} mt={4}>
                  <Typography mb={"10px"}>
                    {t("valetServiceNewFees")}
                  </Typography>
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    gap={"4px"}
                    bgcolor={"#38383880"}
                    p={"20px"}
                    borderRadius={"12px"}
                  >
                    <Typography>{t("price")}: </Typography>
                    <Stack
                      direction={i18n.language === "en" ? "row" : "row-reverse"}
                      alignItems={"center"}
                      gap={"4px"}
                    >
                      <img height={13} src={sarIcon} alt="" />
                      <Typography fontSize={"17px"}>{priceToDisplay}</Typography>
                    </Stack>
                  </Box>
                  <Typography color={"primary"} mt={"10px"}>
                    {t("PaidFeeNotRefundableAndExtraFeesMightApplied")}
                  </Typography>
                </Box>

                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  mt={3}
                  mb={2}
                  gap={3}
                  sx={{ cursor: "pointer" }}
                  onClick={() => setExtended((prev) => !prev)}
                >
                  <Typography color={"primary"} fontWeight={500}>
                    {t("usagePolicy")}
                  </Typography>
                  <Box sx={{ transform: extended ? "scaleY(-1)" : "unset" }}>
                    <img height={8} src={downArrowIcon} alt="" />
                  </Box>
                </Stack>

                {extended &&
                  policyPoints.map((point) => (
                    <Stack key={point} direction={"row"} gap={1.2} mb={0.4}>
                      <Box
                        minWidth={"5px"}
                        height={"5px"}
                        bgcolor={"#D0D0D0"}
                        mt={"10px"}
                      />
                      <Typography color={"#D0D0D0"} lineHeight={"26px"}>
                        {t(point)}
                      </Typography>
                    </Stack>
                  ))}
              </Box>

              <LoadingButton
                sx={{ minWidth: { xs: "100%", sm: "310px" }, mt: 6 }}
                variant="contained"
                size="large"
                loading={isPending}
                type="submit"
              >
                {t("updateBooking")}
              </LoadingButton>
            </>
          )}
        </Stack>
      </form>
    </Box>
  );
};

export default ExtendForm;
