import { Stack, Button, Typography, Box } from "@mui/material";
import Logo from "src/assets/svg-icons/arsann-logo.svg";
import GlobalIcon from "src/assets/svg-icons/global.svg";
import { useLocale } from "src/hooks/use-locale";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const PreBookingTobBar = () => {
  const { locale, setLocale } = useLocale();
  const theme = useTheme();
  const matchesSmallUp = useMediaQuery(theme.breakpoints.up("sm"));

  const handleLanguageChange = () => {
    window.location.reload();
    setLocale(locale === "en" ? "ar" : "en");
  };

  const Arabic = matchesSmallUp ? "العربية" : "ع";
  const English = matchesSmallUp ? "English" : "En";

  return (
    <Stack
      py={"50px"}
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      sx={{ backdropFilter: "blur(5px)" }}
    >
      <Box
        p={"23px 20px"}
        bgcolor={"#272727E5"}
        sx={{
          margin: "0 auto",
          transform: "translateX(50%)",
        }}
        borderRadius={"20px"}
      >
        <img src={Logo} width={59} alt="" />
      </Box>

      <Button
        onClick={handleLanguageChange}
        size="medium"
        sx={{
          textTransform: "unset",
          bgcolor: "#383838",
          minWidth: { xs: "unset", md: "141px" },
        }}
      >
        <Stack direction={"row"} gap={1} alignItems={"center"}>
          <img src={GlobalIcon} alt="" height={17} />
          <Typography color={"textPrimary"}>
            {locale === "ar" ? English : Arabic}
          </Typography>
        </Stack>
      </Button>
    </Stack>
  );
};

export { PreBookingTobBar };
