import { useMutation, useQuery } from "@tanstack/react-query";
import apiClient from "./apiClient";
import {
  ICancelCarReqBody,
  ICarReqBody,
  ICarRequestResponse,
  IPayBody,
} from "src/types/car-request";

export const useGetCarRequestTransaction = (ticketId: string) =>
  useQuery<any, any, ICarRequestResponse>({
    queryKey: ["get-car-request-trans"],
    queryFn: async () => {
      return apiClient.get(`/getCarReqTransaction?ticketId=${ticketId}`);
    },
  });

export const useCreateCarRequestMutation = () =>
  useMutation<void, { error: any }, ICarReqBody>({
    mutationFn: async (data) => {
      return apiClient.post(`/createCarRequest`, data);
    },
  });

export const useCancelCarRequestMutation = () =>
  useMutation<void, { error: any }, ICancelCarReqBody>({
    mutationFn: async (data) => {
      return apiClient.post(`/cancelCarRequest`, data);
    },
  });

export const usePayMutation = () =>
  useMutation<void, any, IPayBody>({
    mutationFn: async (data) => {
      return apiClient.post(`/initiateOnlinePayment`, data);
    },
  });

export const useVerifyRedirectedPaymentMutation = () =>
  useMutation<void, any, { ticketId: string; sessionId: string }>({
    mutationFn: async (data) => {
      return apiClient.post(`/verifyOnlinePayment`, data);
    },
  });
