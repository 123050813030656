import React from "react";
import { Box } from "@mui/material";
import KAFDImage from "src/assets/kafd.png";
import { TobBar } from "../components/top-bar";
import ExtendForm from "./extend-form";

const ExtendBookingPage: React.FC = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      position={"relative"}
      px={{ xs: 2, sm: 4 }}
      pb={6}
    >
      <Box position={"absolute"} top={"0"} zIndex={0}>
        <img src={KAFDImage} width={"100%"} alt="" />
      </Box>
      <Box width={"100%"} maxWidth={"1340px"} zIndex={3}>
        <TobBar />
        <ExtendForm />
      </Box>
    </Box>
  );
};

export default ExtendBookingPage;
