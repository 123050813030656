import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import CarsImage from "src/assets/cars.png";
import { useTranslation } from "react-i18next";
import { PreBookingTobBar } from "../pre-booking/components/top-bar";
import ShieldIcon from "src/assets/svg-icons/shield.svg";

const policyPoints = ["policyPoint1", "policyPoint2", "policyPoint3"];

const PolicyUsage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      px={{ xs: 2, sm: 4 }}
      justifyContent="center"
      pb={6}
      position={"relative"}
    >
      <Box position={"absolute"} top={"0"} zIndex={0}>
        <img
          src={CarsImage}
          height={408}
          width={"100%"}
          style={{ objectFit: "cover" }}
          alt=""
        />
      </Box>
      <Box width={"100%"} maxWidth={"900px"} zIndex={3}>
        <PreBookingTobBar />

        <Box borderRadius={"12px"} overflow={"hidden"}>
          <Stack
            bgcolor={"#272727"}
            padding={{ xs: "40px 30px 40px 30px", md: "70px 113px 80px 113px" }}
          >
            <Stack mb={3} direction={"row"} gap={"12px"} alignItems={"center"}>
              <img height={30} src={ShieldIcon} alt="" />
              <Typography
                color={"primary"}
                fontSize={{ xs: "20px", md: "24px" }}
                fontWeight={700}
                textTransform={"uppercase"}
                component={"h1"}
              >
                {t("arsannUsagePolicy")}!
              </Typography>
            </Stack>

            {policyPoints.map((point) => (
              <Stack key={point} direction={"row"} gap={1.2} mb={0.8}>
                <Box
                  minWidth={"5px"}
                  height={"5px"}
                  bgcolor={"#D0D0D0"}
                  mt={"10px"}
                />
                <Typography
                  fontSize={"18px"}
                  color={"#D0D0D0"}
                  lineHeight={"26px"}
                >
                  {t(point)}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default PolicyUsage;
