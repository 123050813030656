import React from "react";
import { Box, Grid, Link, Stack, Typography } from "@mui/material";
import DoneIcon from "src/assets/svg-icons/done.svg";
import DangerIcon from "src/assets/svg-icons/danger.svg";
import { QRCodeSVG } from "qrcode.react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import ItemBox from "./item-box";
import GoogleMapIcon from "src/assets/svg-icons/google_map.svg";
import { useSearchParams } from "react-router-dom";

interface IProps {
  bookingData: any;
}

const BookedSuccessfully: React.FC<IProps> = ({ bookingData }) => {
  let [searchParams] = useSearchParams();
  const isExtended = searchParams.get("extended");
  const { t, i18n } = useTranslation();
  const { locationLatitude, locationLongitude } = bookingData;

  const googleMapsUrl = `https://www.google.com/maps?q=${locationLatitude},${locationLongitude}`;

  return (
    <Box overflow={"hidden"} borderRadius={"12px"}>
      <Box
        bgcolor={"#272727"}
        padding={{
          xs: "30px 16px",
          md: "30px 53px 100px 53px",
          lg: "30px 113px 100px 113px",
        }}
      >
        <Stack alignItems={"center"}>
          <img src={DoneIcon} alt="" />
          <Typography
            color={"primary"}
            fontSize={"24px"}
            fontWeight={700}
            mb={0.7}
          >
            {t(
              isExtended === "yes"
                ? "timeUpdatedSuccessfully"
                : "bookedSuccessfully"
            )}
          </Typography>
          <Typography fontSize={"14px"} mb={0.7}>
            {t("yourGuestReceivedSameMessage")}
          </Typography>
          <Typography fontSize={"14px"}>{t("extraChargesApplied")}</Typography>
        </Stack>
        {/* divider */}
        <Box my={4} bgcolor={"#383838"} height={"1px"} width={"100%"} />
        <Grid container spacing={0}>
          <Grid container xs={12} md={3.8}>
            <Stack
              p={"30px 34px 26px 33px"}
              border={"1px solid"}
              borderColor={"#D4AD53"}
              borderRadius={"6px"}
              alignItems={"center"}
              mb={{ xs: 2, md: 0 }}
            >
              <QRCodeSVG
                bgColor={"#272727"}
                fgColor={"white"}
                value={bookingData.ticketId}
                size={200}
              />
              <Box mt={"38px"} mb={1}>
                <img src={DangerIcon} alt="" />
              </Box>
              <Typography align="center" mb={2}>
                {t("pleaseNoteBookingTicketValidFor")}
              </Typography>
              <Typography color={"primary"} align="center">
                {t("paidFeeIsNotRefundable")}
              </Typography>
            </Stack>
          </Grid>
          <Grid xs={0} md={0.4} />
          <Grid container xs={12} md={7.8}>
            <Stack width={"100%"} gap={2}>
              <ItemBox label={t("ticketNum")} value={bookingData.ticketId} />

              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <ItemBox
                    label={t("locationName")}
                    value={
                      i18n.language === "en"
                        ? bookingData.locationName
                        : bookingData.locationNameAr
                    }
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box
                    bgcolor={"#222222"}
                    borderRadius={"6px"}
                    p={"20px 12px 20px 24px"}
                    display={"flex"}
                    gap={"20px"}
                    flexGrow={1}
                    minWidth={"50%"}
                    alignItems={"center"}
                  >
                    <Typography
                      minWidth={"100px"}
                      fontSize={"14px"}
                      color={"#737373"}
                    >
                      {t("googleMaps")}
                    </Typography>
                    <Link
                      href={googleMapsUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      underline="hover"
                      display={"flex"}
                      gap={1.5}
                      alignItems={"center"}
                    >
                      <Typography color={"#F1F1F1"} fontSize={"14px"}>
                        {googleMapsUrl.slice(0, 22)}
                      </Typography>
                      <img src={GoogleMapIcon} alt="" />
                    </Link>
                  </Box>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <ItemBox
                    label={t("arrivingDate")}
                    value={moment(bookingData.arrivingTime).format("D/M/YYYY")}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <ItemBox
                    label={t("arrivingTime")}
                    value={moment(bookingData.arrivingTime).format("h:mm A")}
                  />
                </Grid>
              </Grid>

              <ItemBox
                label={t("leavingTime")}
                value={moment(bookingData.leavingTime).format("h:mm A")}
              />

              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <ItemBox label={t("hostName")} value={bookingData.hostName} />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <ItemBox
                    label={t("hostMobile")}
                    value={bookingData.hostMobile}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <ItemBox
                    label={t("guestName")}
                    value={bookingData.customerName}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <ItemBox
                    label={t("guestMobile")}
                    value={bookingData.customerMobile}
                  />
                </Grid>
              </Grid>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default BookedSuccessfully;
